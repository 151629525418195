@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        @apply text-slate-600;
    }

    h1 {
        @apply text-2xl;
    }
}
